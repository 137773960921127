
.services {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 .imgCleaning1 {
   object-position: 30% 30%;
 }
 .imgCleaning2 {
   object-position: 40% 100%;
 }
 .imgCleaning3 {
   object-position: 0% 100%;
 }
 .imgCleaning4 {
   object-position: 0% 100%;
 }
}