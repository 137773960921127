/* make the customizations to boostrap */
$theme-colors: (
    "info": teal,
    "danger": tomato,
    "primary": #B31C23
);

$link-color: #B31C23;
$link-color-hover: #B31C23;


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";