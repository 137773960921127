
.home {
  .img1container {
    position: relative;
  }
  .img1 {
      width: 100%;
      height: 500px;
      object-fit: cover;
      object-position: 80% 80%;
    }
  .img1content {
    position: absolute;
    top: 10%;
    left: 10%;
    h2 {
      color: white;
      text-shadow: 1px 1px black;
      font-weight: 600;
    }
    a {
      color: white;
      border-color: white;
      border-width: 2px;
      font-size: 1.2rem;
    }
  }
}

